* {
    box-sizing: border-box;
}

$dark: #222;
$light: #fff;
$blue: #54c7cc;
$green: #becc54;
$red: #D66871;

html, body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background: $light;
    // background-color: darken($blue, 20%);
    // background: linear-gradient(20deg, darken($blue, 24%) 20%,darken($blue, 10%) 78%);
}
#root {
    z-index: 2;
    height: 100%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
}

@import './slider.scss';
@import './topline.scss';
@import './cards.scss';
@import './screenreceiver.scss';
@import './window.scss';
@import './sport.scss';
@import './slide1.scss';
@import './slide3.scss';


.dot-container {
    position:absolute;
    left:0;
    top:0;
    overflow:visible;
    z-index:5000;
    pointer-events:none;
  }
  .dot {
    position: absolute;
    pointer-events: none; /*performance optimization*/
  }
  .particles-js-canvas-el {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}