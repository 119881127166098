#topline {
    height: 8rem;
    width: 100%;
    // background: linear-gradient(270deg, rgba(84, 199, 204, 0.71) 0%, #54C7CC 100%);
    background-color: $light;
    border-bottom: 1px solid rgba(0,0,0,0.18);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
    img {
        height: calc(100% - 4rem);
    }
    .time {
        font-weight: bold;
        font-size: 4rem;
    }
}

.index {
    height: calc(100vh - 8rem);
    width: 100%;
    padding: 4rem;
}