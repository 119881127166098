.main-slider {
    height: 100%;
    width: 100%;
    // background-color: yellow;

    .slick-slider {
        height: 100%;
        width: 100%;

        .slick-list {
            height: 100%;
            width: 100%;
            .slick-track {
                height: 100%;
                width: 100%;
                .slick-slide {
                    height: 100%;
                    width: 100%;
                    outline: none;
                    &>div {
                        height: 100%;
                        width: 100%;
                        .slide {
                            height: 100%;
                            width: 100%;
                            opacity: 0;
                            transition: .2s ease;
                            outline: none;
                        }
                    }
                    &.slick-active {
                        &>div {
                            .slide {
                                opacity: 1;
                                // background-color: blue;
                            }
                        }
                    }
                }
            }
        }

        .slick-dots {

        }

    }

}