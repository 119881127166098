.slide1-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 2rem 2rem;
    grid-template-areas:
        "trends visitor-total visitor-new visitor-convs"
        "trends referrers referrers goals"
        "trends referrers referrers goals";
    height: 100%;
    width: 100%;

    .trends { grid-area: trends; overflow: hidden; }
    .referrers { grid-area: referrers; }
    .visitor-total { grid-area: visitor-total; }
    .visitor-new { grid-area: visitor-new; }
    .visitor-convs { grid-area: visitor-convs; }
    .goals { grid-area: goals; }
}

.trendlist {
    .listitem {
        background-color: rgba(#000, 0.03);
        border-radius: 20px;
        padding: 0.9rem 1.1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;
        overflow: hidden;
        .label, .site { 
            padding: 0.6rem 1rem;
            border-radius: 15px;
        }
        .label {
            background-color: #fff;
            min-width: 60px;
            text-align: center;
        }
        .site {
            padding-left: 1rem;
        }
    }
}

.site-referrers {
    .content {
        height: calc(100% - 8rem);
    }
}

.ref-dia {
    height: 100%;
    width: 100%;
    .item {
        flex: 1;
        width: 100%;
        margin-bottom: 2rem;
        .label {
            height: 100%;
            width: 230px;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: bold;
        }
        .bar {
            margin-top: 0.8rem;
            width: 100%;
            height: 16px;
            border-radius: 8px;
            background-color: rgba(#000, 0.03);
            padding: 3px;
            .bar-inner {
                height: 10px;
                background-color: $blue;
                border-radius: 5px;
            }
        }
    }
}

.visitor-info {
    display:flex;
    height:100%;
    width: 100%;
    font-size: 5rem;
    font-weight: bold;
    align-items: flex-end;
    justify-content: flex-end;
    small {
        font-size: 2rem;
        padding-bottom: 0.6rem;
        padding-left: 1rem;
    }
}