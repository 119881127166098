.sport-wrapper {
    position: fixed;
    top: 100%;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s ease;
    z-index: 40;
    background-color: $light;
    &.active {
        top: 0;
    }
    .inner {
        margin: 0 auto;
        width: 1200px;
        img {
            width: 100%;
            padding-left: 170px;
        }
    }
    .title {
        font-weight: bolder;
        text-transform: uppercase;
        font-size: 6rem;
        text-align: center;
    }
}