#window-opener {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 9999;
  }
  #window-opener.active {
    top: 0;
  }
  #window-opener .wave, #window-opener .back {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    transition: 2.2s cubic-bezier(0.21, -0.43, 0.25, 1);
  }
  #window-opener .wave1 {
    background-color: red;
    bottom: -100%;
  }
  #window-opener .wave2 {
    background-color: yellow;
    bottom: -110%;
  }
  #window-opener .wave3 {
    background-color: blue;
    bottom: -120%;
  }
  #window-opener .wave4 {
    background-color: green;
    bottom: -130%;
  }
  #window-opener .wave5 {
    background-color: orange;
    bottom: -140%;
  }
  #window-opener .wave6 {
    background-color: #777;
    bottom: -150%;
  }
  #window-opener .back {
    background-color: #333;
    bottom: -100%;
  }
  #window-opener .image {
    position: absolute;
    left: 100%;
    bottom: 0;
  }
  #window-opener .image img {
    height: 300px;
  }
  #window-opener.active .wave {
    bottom: 100%;
  }
  #window-opener.active .back {
    bottom: 0;
  }
  #window-opener.active-image .image {
    transition: 3s cubic-bezier(0.21, -0.43, 0.25, 1);
    bottom: 110%;
    left: 0%;
    transform: rotate(60deg);
  }
  #window-opener .text {
    position: absolute;
    bottom: -100%;
    left: 50%;
    font-size: 6rem;
    font-weight: bold;
    color: #fff;
    transition: 1s cubic-bezier(0.21, -0.43, 0.25, 1);
  }
  #window-opener.active-text .text {
    bottom: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
  }
  