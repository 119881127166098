.slide-3 {
    display: block !important;
}
.slide3-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 2rem 2rem;
    height: 100%;
    width: 100%;
    grid-template-areas:
        "news news news calendar"
        "news news news calendar"
        "co2 energy solar calendar";

    .news { grid-area: news; }
    .calendar { grid-area: calendar; }
    .co2 { grid-area: co2; }
    .energy { grid-area: energy; }
    .solar { grid-area: solar; }


    .site-news {
        .news {
            height: 100%;
            width: 100%;
            .inner {
                height: 100%;
                width: 100%;
                position: relative;
                background-size: cover;
                background-position: center;
            }
            .typo {
                position: absolute;
                bottom: 0;
                left: 0;
                max-height: 400px;
                width: 100%;
                background-color: rgba(#000, 0.59);
                padding: 2rem;
                .date {
                    font-weight: bold;
                    font-size: 1.4rem;
                    color: $light;
                }
                .title {
                    font-size: 3.8rem;
                    padding: 0;
                    font-weight: bold;
                    color: $light;
                }
                .content {
                    padding: 1.4rem 0 0 0;
                    font-size: 1.5rem;
                    height: auto;
                    color: $light;
                    line-height: 1.4;
                }
            }
        }
    }

    .site-calendar {
        .cal-title {
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
            &:not(:first-of-type) {
                margin-top: 3rem;
            }
        }
        .cal-entry {
            background-color: rgba(#000, 0.03);
            border-radius: 20px;
            padding: 0.9rem 1.1rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: bold;
            .label { 
                padding: 0.6rem 1rem;
                border-radius: 15px;
            }
        }
        .cal-spacer {
            width: 100%;
            height: 40px;
        }
    }

    .site-infoboard {
        @media (min-width: 1500px) {
            p {
                font-size: 1.8rem;
            }
        }
    }
}