.screen-receiver {
    display: none;

    &.visible {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($dark, 0.8);
        z-index: 20;
        padding: 2rem;
        .inner {
            height: 100%;
            width: 100%;
            background-color: $green;
        }
    }
}