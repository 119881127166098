.info-card  {
    height: 100%;
    width: 100%;
    border: 1px solid rgba(#000, 0.18);

    .title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 3rem 1.8rem 1rem 1.8rem;
        font-weight: bolder;
        font-size: 2rem;
        color: $dark;
        svg {
            color: $blue;
            font-size: 2.4rem;
        }
        svg + span {
            padding-left: 1rem;
        }
    }
    .content {
        padding: 1rem 1.8rem 2rem 1.8rem;
        color: $dark;
        height: 100%;
        overflow: hidden;
    }
    .title + .content {
        height: calc(100% - 6.4rem);
    }
}